import { Dropdown, Space } from "antd";
import React, { useEffect } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { RiMenu2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Socket } from "../../../socket";
const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const { authUser } = useSelector((state) => state.AuthR);

  const setInitialConnection = () => {
    if (authUser) {
      Socket.getInstance(authUser._id);
    }
  };

  useEffect(setInitialConnection, []);

  const navigate = useNavigate();
  let menuItem = [
    {
      name: "Dashboard",
      link: "/dashboard",
    },
    {
      name: "Search",
      link: "/search",
    },
    {
      name: "Direct Notification",
      link: "/directNotification",
    },
    {
      name: "Broadcast Message",
      link: "/broadcastMessage",
    },
    {
      name: "News",
      link: "/news",
    },
    {
      name: "Banner",
      link: "/banner",
    },
    {
      name: "Refund Orders",
      link: "/refundOrders",
    },
    {
      name: "Cancellation",
      link: "/cancellation",
    },
    {
      name: "Pages",
      link: "/pages",
    },
  ];
  const renderMenuItems = () => {
    if (menuItem && menuItem.length > 0)
      return menuItem.map((item) => {
        return (
          <span
            className={`text-[#878686]  font-roboto-bold cursor-pointer w-[160px] h-[50px] flex items-center justify-center ${
              pathname === item.link
                ? "border-2 border-primary-brown underline text-primary-brown underline-offset-8 text-[16px] w-[180px]"
                : "border-none text-[#878686] text-[14px]"
            }`}
            onClick={() => navigate(item.link)}
          >
            {item.name}
          </span>
        );
      });
  };
  const items = [
    {
      label: (
        <span onClick={() => navigate("/petPostSetting")}>
          Pet Post Setting
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span onClick={() => navigate("/petProductSetting")}>
          Product Post Setting
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span onClick={() => navigate("/petServicesSetting")}>
          Service Post Setting
        </span>
      ),
      key: "3",
    },
  ];

  return (
    <>
      <div className="lg:flex hidden items-center gap-7  pt-6 pb-3 px-4 bg-[#F1F1FD] ">
        {renderMenuItems()}
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <Space className="cursor-pointer">
            <span className="text-[#878686] text-[14px] font-medium">
              Setting
            </span>
            <CaretDownOutlined className="text-[#878686] text-[14px] font-medium" />
          </Space>
        </Dropdown>
      </div>
      <div className="lg:hidden flex items-center justify-end my-3 px-5">
        <RiMenu2Fill />
      </div>
    </>
  );
};

export default Navbar;
