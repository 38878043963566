import React, { useEffect } from "react";
// import LayoutImage from "../../assets/images/loginScreen.jpg";
import LayoutImage from "../../assets/images/petpal.jpeg";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
const AuthLayout = ({ children, heading, content }) => {
  const { LoginImages } = useSelector((state) => state.CrudR);
  console.log("loginImageAdmin", LoginImages);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      DataGetAction("getLoginImages", "", "FetchRecord", "LoginImages", "", "")
    );
  }, []);
  return (
    <div className="h-screen w-screen">
      <Row className="h-full w-full">
        <Col xl={12} lg={12} md={12} className="md:flex hidden">
          <img
            src={LoginImages ? LoginImages?.loginImageAdmin : LayoutImage}
            alt=""
            className="h-full w-full"
          />
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className="flex items-center justify-center"
        >
          <div className="h-[95%] w-[95%] bg-[#fff] drop-shadow-xl rounded-xl flex items-center justify-center">
            <div className="md:w-[500px] w-full p-5">
              <h2 className="text-[30px] font-roboto-bold text-center">
                {heading}
              </h2>
              <p className="text-[18px] font-roboto-medium text-center">
                {content}
              </p>
              {children}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuthLayout;
