const SocketResponseState = require("../redux/actions/socket").SocketResponseState;
const io = require("socket.io-client");
const Socket = (function () {
  let instance;
  function createInstance(userId) {
    console.log("==== userId ====", userId);
    const socket = io(process.env.REACT_APP_ROOT_URL, {
      withCredentials: true,
      transports: ["websocket"],
      query: {
        clientId: userId,
        from: "admin",
      },
    });
    socket.on("connect", function (err, data) {
      console.log("========= socket ============ Connection Created", err, data);
    });
    socket.on("disputeMessage", function (data) {
      SocketResponseState("disputeMessage", data);
    });

    socket.on("newActivityCreated", function (data) {
      SocketResponseState("newActivityCreated", data);
    });

    socket.on("FetchOrderList", function (data) {
      SocketResponseState("OrderFetch", {fetchOrder:1});
    });
    socket.on("newOrder", function (data) {
      SocketResponseState("UpdateMenuData", true);
    });
    socket.on("reduceTheMenuCount", function (data) {
      SocketResponseState("reduceTheMenuCount", data);
    });
    socket.on("increaseTheMenuCount", function (data) {
      SocketResponseState("updateMenuCard", data);
    });
    socket.on("newProductAdded", function (data) {
      SocketResponseState("updateMenuCard", { totalProduct: 1 });
    });
    socket.on("newServicesAdded", function (data) {
      SocketResponseState("updateMenuCard", { totalService: 1 });
    });
    socket.on("newService", function (data) {
      SocketResponseState("NewBookingCome", 1);
    });

    function onConnect(userId) {
      return new Promise((resolve, reject) => {
        socket.emit("connect", { id: userId });
        resolve();
      });
    }

    function socketDisconnet() {
      return new Promise((resolve, reject) => {
        socket.emit("disconnect", {});
        resolve();
      });
    }
    return {
      onConnect,
      socketDisconnet,
    };
  }
  return {
    getInstance: function (userId) {
      if (!instance) {
        instance = createInstance(userId);
      }
      return instance;
    },
  };
})();
module.exports = {
  Socket: Socket,
};
