import React, { useState, useEffect } from "react";
import DetailModal from "../../../components/Modal/DedailModal";
import BackgroundImg from "../../../assets/images/backgroundUserCard.png";
import NavCard from "../../../components/Layout/components/NavCard";
import { ImUser } from "react-icons/im";
import { IoCall, IoMailSharp } from "react-icons/io5";
import { MdAddToPhotos } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import placeholderImage from "../../../assets/images/placeholder.webp";
import { Col, Row, Spin, Button } from "antd";
import DetailCard from "../../../components/Layout/components/DetailCard";
import greenGraph from "../../../assets/images/greenGraph.png";
import yellowGraph from "../../../assets/images/yellowGraph.png";
import blueGraph from "../../../assets/images/blueGraph.png";
import redBar from "../../../assets/images/redbar.png";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";

const UserGraphModal = ({ data, role, visible, onClose }) => {
  const [loading, setLoading] = useState(true);

  const userData = Array.isArray(data) && data.length > 0 ? data[0] : {}; // Accessing the first element of the data array
  const { fetchingModalSpinner } = useSelector((state) => state.CrudR);
  const navigate = useNavigate();
  // Reusable function for downloading files
  const downloadFiles = (files, prefix) => {
    files.forEach((url, index) => {
      saveAs(url, `${prefix}_${index + 1}.jpg`);
    });
  };

  // Handlers for downloads
  const downloadLicenses = () => {
    const licenses = userData.licenses || [];
    downloadFiles(licenses, "license");
  };

  const downloadBusiness = () => {
    const business = userData.businessProfile || [];
    downloadFiles(business, "business");
  };

  // Optional function for viewing images
  const viewImages = (type) => {
    const files = type === "licenses" ? userData.licenses || [] : userData.businessProfile || [];

    // Logic for displaying images, e.g., in a modal or preview section
    console.log(`Viewing ${type} images:`, files);
  };

  return (
    <DetailModal modalName="View_User_Graph" width={"1300px"} customClassName={"custom-model-class"} visible={visible} onCancel={onClose}>
      <div className="relative">
        {fetchingModalSpinner && (
          <div className="absolute inset-0 flex justify-center items-center z-20">
            <Spin size="large" />
          </div>
        )}
        <div
          style={{
            backgroundImage: `url(${BackgroundImg})`,
            boxShadow: "0px 0px 5px #0000000A",
          }}
          className="w-full md:h-[188px] flex items-center justify-around flex-wrap"
        >
          <NavCard
            title="Total Purchase:"
            count={`$${userData?.purchase || 0}`}
            iconColorbg="#FBD6E1"
            color="#FF2366"
            routeName=""
            icon={<ImUser size={20} />}
            customClick={() => {
              navigate("/orders", { state: { userId: userData._id } });
            }}
          />
          <div>
            <h3 className="text-[28px] font-roboto-medium capitalize">{userData?.storeName || "---"}</h3>
            <div className="flex items-center gap-2">
              <IoMailSharp fill="#722F38" />
              <p className="text-[16px] font-roboto-regular">{userData?.email || "---"}</p>
            </div>
            <div className="flex items-center gap-2">
              <IoCall fill="#722F38" />
              <p className="text-[16px] font-roboto-regular">{userData?.phoneNumber || "---"}</p>
            </div>
            <div className="flex flex-col w-[210px] gap-2">
            {userData.licenses && userData.licenses.length > 0 && (
              <Button type="primary" onClick={downloadLicenses}>
                Download Licenses
              </Button>
              )}
              {userData.businessProfile && userData.businessProfile.length > 0 && (
                <Button type="primary" onClick={downloadBusiness}>
                  Download Business Licenses
                </Button>
              )}
            </div>
          </div>
          <img src={userData?.profileImageUrl || placeholderImage} alt="" className="w-[157px] h-[139px] rounded-lg drop-shadow" />
          <NavCard
            title="Total Booking:"
            count={`$${userData?.bookings || 0}`}
            iconColorbg="#FBD6E1"
            color="#FF2366"
            routeName=""
            customClick={() => {
              navigate("/booking", { state: { userId: userData._id } });
            }}
            icon={<ImUser size={20} />}
          />
        </div>
        <div>
          <h3 className="py-2 text-2xl font-roboto-medium">Posts</h3>
          <Row>
            <Col xl={6} lg={6} md={12} sm={24} xs={24}>
              <DetailCard
                title="Total Found Posts"
                count={userData?.posts?.totalFind || 0}
                iconColorbg="#FFF9E1"
                color="#FFD950"
                routeName=""
                icon={<MdAddToPhotos size={30} />}
                image={yellowGraph}
                customClick={() => {
                  navigate("/posts", { state: { userId: userData._id } });
                }}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={24} xs={24}>
              <DetailCard
                title="Total Adopt Posts"
                count={userData?.posts?.totalAdopt || 0}
                iconColorbg="#E0ECFF"
                color="#4791FF"
                routeName=""
                icon={<MdAddToPhotos size={30} />}
                image={blueGraph}
                customClick={() => {
                  navigate("/posts", { state: { userId: userData._id } });
                }}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={24} xs={24}>
              <DetailCard
                title="Total Sale Posts"
                count={userData?.posts?.totalSale || 0}
                iconColorbg="#D4F4E8"
                color="#02BC77"
                routeName=""
                icon={<GrMoney size={30} />}
                image={greenGraph}
                customClick={() => {
                  navigate("/posts", { state: { userId: userData._id } });
                }}
              />
            </Col>
          </Row>
        </div>
        {role !== "user" && (
          <>
            <div>
              <h3 className="py-2 text-2xl font-roboto-medium">Products</h3>
              <Row>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    background="#002165"
                    title="Total Products"
                    count={userData?.products || 0}
                    iconColorbg="#FBD6E1"
                    color="#FF2366"
                    routeName=""
                    icon={<GrMoney size={30} />}
                    image={redBar}
                    customClick={() => {
                      navigate("/petProductPosting", { state: { userId: userData._id } });
                    }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    title="Total Orders"
                    count={userData?.orderdata?.orderTotal || 0}
                    iconColorbg="#FBD6E1"
                    color="#FF2366"
                    routeName=""
                    icon={<MdAddToPhotos size={30} />}
                    image={yellowGraph}
                    customClick={() => {
                      navigate("/orders", { state: { merchantId: userData._id } });
                    }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    title="Orders Pending"
                    count={userData?.orderdata?.pendingCount || 0}
                    iconColorbg="#FBD6E1"
                    color="#FF2366"
                    routeName=""
                    icon={<MdAddToPhotos size={30} />}
                    image={blueGraph}
                    customClick={() => {
                      navigate("/orders", { state: { query: "search", key: "status", value: "pending", merchantId: userData._id } });
                    }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    title="Orders Completed"
                    count={userData?.orderdata?.completedCount || 0}
                    iconColorbg="#D4F4E8"
                    color="#02BC77"
                    routeName=""
                    icon={<GrMoney size={30} />}
                    image={greenGraph}
                    customClick={() => {
                      navigate("/orders", { state: { merchantId: userData._id, query: "search", key: "status", value: "completed" } });
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <h3 className="py-2 text-2xl font-roboto-medium">Services</h3>
              <Row>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    background="#FD8E06"
                    title="Total Services"
                    count={userData?.services || 0}
                    iconColorbg="#FFDAE5"
                    color="#722f38"
                    routeName=""
                    icon={<GrMoney size={30} />}
                    image={redBar}
                    customClick={() => {
                      navigate("/services", { state: { userId: userData._id } });
                    }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    title="Total Bookings"
                    count={userData?.servicebookingdata?.bookingTotal || 0}
                    iconColorbg="#FFF9E1"
                    color="#FFD950"
                    routeName=""
                    icon={<MdAddToPhotos size={30} />}
                    image={yellowGraph}
                    customClick={() => {
                      navigate("/booking", { state: { userId: userData._id } });
                    }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    title="Current booking"
                    count={userData?.servicebookingdata?.pendingCount || 0}
                    iconColorbg="#E0ECFF"
                    color="#4791FF"
                    routeName=""
                    icon={<MdAddToPhotos size={30} />}
                    image={blueGraph}
                    customClick={() => {
                      navigate("/booking", { state: { userId: userData._id } });
                    }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    title="Cancelled booking"
                    count={userData?.servicebookingdata?.cancelledCount || 0}
                    iconColorbg="#E0ECFF"
                    color="#722f38"
                    routeName=""
                    icon={<MdAddToPhotos size={30} />}
                    image={blueGraph}
                    customClick={() => {
                      navigate("/booking", { state: { userId: userData._id } });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                  <DetailCard
                    title="Total Completed"
                    count={userData?.servicebookingdata?.completedCount || 0}
                    iconColorbg="#D4F4E8"
                    color="#02BC77"
                    routeName=""
                    icon={<GrMoney size={30} />}
                    image={greenGraph}
                    customClick={() => {
                      navigate("/booking", { state: { userId: userData._id } });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    </DetailModal>
  );
};

export default UserGraphModal;
