import React, { useEffect, useState } from "react";
import MerchantList from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import moment from "moment";
import Action from "../../components/Table/components/Action";
import EditMerchant from "./components/EditMerchant";
import ShowModal from "../../components/Modal/ShowModal";
import DeleteModal from "../../components/Modal/DeleteModal";
import { renderStatus } from "../../utils/CommonFunctions";
import ViewOtp from "./components/ViewOtp";
import { Avatar } from "antd";
import placeholder from "../../assets/images/placeholder.webp";
import ChangePassword from "./components/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import UserGraphModal from "../User/components/UserGraphModal";
const Index = () => {
  const dispatch = useDispatch();
  const { merchantStats } = useSelector((state) => state.CrudR);
  const crudProps = useSelector((state) => state.CrudR);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const fetchShopDetail = (userId) => {
    dispatch(DataGetAction("showCustomerStats", { _id: userId }, "FetchRecord", "merchantStats", "fetchingResult"));
  };

  useEffect(() => {
    if (selectedUserId && crudProps["View_User_Graph"]) {
      fetchShopDetail(selectedUserId);
    }
  }, [selectedUserId, crudProps["View_User_Graph"]]);


  const handleUserClick = (userId) => {
    setSelectedUserId(userId);
    dispatch({ type: "Show_Modal", modalName: "View_User_Graph" });
  };

  const handleCloseModal = () => {
    dispatch({ type: "Hide_Modal", modalName: "View_User_Graph" });
    setSelectedUserId(null);
  };

  // Cleanup effect to ensure modal doesn't remain open on route change
  useEffect(() => {
    return () => {
      dispatch({ type: "Hide_Modal", modalName: "View_User_Graph" });
    };
  }, [dispatch]);

  const renderAction = (record) => {
    return (
      <div className="flex flex-col">
        {record.status === "block" && (
          <DeleteModal
            apiName={"updateUserStatus"}
            recordName="userList"
            requestType="EditRecord"
            method="PUT"
            selectedItem={{ _id: record._id, status: "active" }}
            title="Active"
            content="Are you sure you want to active this record?"
            buttonName="Active"
          />
        )}
        {record.status === "active" && (
          <DeleteModal
            title="Block"
            apiName={"updateUserStatus"}
            recordName="userList"
            requestType="EditRecord"
            method="PUT"
            selectedItem={{ _id: record._id, status: "block" }}
            content="Are you sure you want to block this record?"
            buttonName="Block"
          />
        )}
        <ShowModal modalName={"Edit_Merchant_Modal"} record={record} name="Edit" />
        {record.verifyPhoneNumber === false && <ShowModal modalName={"Show_OTP_modal"} record={record} name="View OTP" />}
        <DeleteModal
          apiName="deleteUser"
          recordName="userList"
          requestType="DeleteRecord"
          selectedItem={record}
          title="Delete"
          content="Are you sure you want to delete this record?"
        />
        <ShowModal modalName={"Change_Merchant_Password_Modal"} record={record} name="Change Password" />
      </div>
    );
  };
  let columns = [
    {
      title: "ID",
      render: (record) => <span>{record.userId}</span>,
      key: "userId",
      width: "80px",
    },
    // {
    //   title: "User Name",
    //   render: (record) => <span>{record.username || "---"}</span>,
    //   key: "username",
    // },
    {
      title: "Full Name",
      render: (record) => (
        <span
          onClick={() => handleUserClick(record._id, record.role)}
          className="capitalize flex items-center gap-2 cursor-pointer underline text-[#4791FF]"
        >
          <Avatar size={55} src={record.profileImageUrl || placeholder} />
          {record.fullName || "---"}
        </span>
      ),
      key: "fullName",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
      key: "phoneNumber",
    },
   
    {
      title: "Profile Views",
      render: (record) => <span>{record.profileViews}</span>,
      key: "profileView",
    },
    {
      title: "Created Date",
      render: (record) => <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>,
      key: "createdAt",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => <Action>{renderAction(record)}</Action>,
      key: "action",
    },
  ];

  return (
    <>
      <Widget className="p-5">
        <TableHeader />
        <MerchantList columns={columns} apiName="getUser" recordName="userList" customQuery={{ role: "merchant" }} />
      </Widget>
      <EditMerchant />
      <ChangePassword />
      <ViewOtp />
      <UserGraphModal
        role={"merchant"}
        data={merchantStats}
        visible={crudProps["View_User_Graph"]} // Use the Redux state for visibility
        onClose={handleCloseModal}
      />
    </>
  );
};

export default Index;
