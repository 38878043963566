import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { useDispatch } from "react-redux";
import { DataRequestAction } from "../../redux/actions/CommonHttp";

const LoginUploader = ({ imageUrl, setImageUrl, imageKey }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      let data = {};
      if (imageKey == "loginImageAdmin") {
        data = {
          loginImageAdmin: info.file.response.url.location,
        };
      } else {
        data = {
          loginImageWeb: info.file.response.url.location,
        };
      }

      setLoading(false);
      dispatch(
        DataRequestAction("PUT", "updateProfile", data, "UPDATE_PROFILE", "")
      );
      setImageUrl(info.file.response.url.location);
    }
  };

  return (
    <div>
      <Upload
        name="file"
        listType="picture-card"
        showUploadList={false}
        className="loginBanner"
        action={`${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        style={{ background: "none !important" }}
      >
        <Button
          loading={loading}
          disabled={loading}
          type="primary"
          className="bg-primary-brown"
        >
          Upload
        </Button>
      </Upload>
    </div>
  );
};

export default LoginUploader;
