import React, { useState } from "react";
import Table from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import moment from "moment";
import Action from "../../components/Table/components/Action";
import ShowModal from "../../components/Modal/ShowModal";
import DeleteModal from "../../components/Modal/DeleteModal";
import { renderStatus } from "../../utils/CommonFunctions";
import { Image, Modal } from "antd";
import EditBanner from "./components/EditBanner";
import ViewDescription from "./components/viewDescription";

const ShortDescriptionCell = ({ description }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const truncatedDescription =
    description?.length > 50
      ? `${description.substring(0, 50)}...`
      : description;

  return (
    <>
      <span
        className="capitalize hover:underline"
        onClick={showModal}
        style={{ cursor: "pointer", color: "#722F38" }}
      >
        {truncatedDescription || "---"}
      </span>
      <Modal
        className="mt-20 text-justify"
        title="Description"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ maxHeight: "350px" }} // Ensures the modal itself does not grow beyond this height
        bodyStyle={{ height: "300px", overflowY: "auto" }} // Fixes the height of the modal body and enables scrolling
      >
        <div className="modal-container">
          <p className="modal-content">{description || "---"}</p>
        </div>
      </Modal>
    </>
  );
};
const Index = () => {
  const renderAction = (record) => {
    record["startDate"] = moment(record.startDate);
    record["endDate"] = moment(record.endDate);

    return (
      <div className="flex flex-col">
        {record.status === "deActivate" && (
          <DeleteModal
            apiName={"updateBanner"}
            recordName="bannerList"
            requestType="EditRecord"
            method="PUT"
            selectedItem={{ _id: record._id, status: "activate" }}
            title="Activate"
            content="Are you sure you want to Activate this record?"
            buttonName="Activate"
          />
        )}
        {record.status === "activate" && (
          <DeleteModal
            title="De-Activate"
            apiName={"updateBanner"}
            recordName="bannerList"
            requestType="EditRecord"
            method="PUT"
            selectedItem={{ _id: record._id, status: "deActivate" }}
            content="Are you sure you want to De-Activate this record?"
            buttonName="De-Activate"
          />
        )}
        <ShowModal
          modalName={"Edit_Banner_Modal"}
          record={record}
          name="Edit"
        />
        <DeleteModal
          apiName="deleteBanner"
          recordName="bannerList"
          requestType="DeleteRecord"
          selectedItem={record}
          title="Delete"
          content="Are you sure you want to delete this record?"
        />
      </div>
    );
  };
  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.bannerId}</span>,
      key: "userId",
      width: "80px",
    },
    {
      title: "Title",
      render: (record) => (
        <div className="flex items-center" style={{ gap: "5px" }}>
          <Image
            src={record.profileImageUrl}
            alt=""
            style={{ width: "40px", height: "40px" }}
            className="rounded-lg"
          />
          <span className="capitalize">{record.title || "---"}</span>
        </div>
      ),
      key: "title",
    },
    {
      title: "Link",
      render: (record) => (
        <a className="text-primary-brown" href={record.hyperLink}>
          Click
        </a>
      ),
      key: "link",
    },
    {
      title: "Start Date",
      render: (record) => (
        <span>{moment(record.startDate).format("MMM-DD-YYYY") || "---"}</span>
      ),
      key: "startDate",
    },
    {
      title: "End Date",
      render: (record) => (
        <span>{moment(record.endDate).format("MMM-DD-YYYY") || "---"}</span>
      ),
      key: "endDate",
    },
    // {
    //   title: "Banner For",
    //   render: (record) => <span>{record.bannerType ? record.bannerType : "App"}</span>,
    //   key: "endDate",
    // },
    {
      title: "Description",
      render: (record) => (
        <ShortDescriptionCell description={record?.description} />
      ),
      key: "description",
    },
    {
      title: "Banner Clicked",
      render: (record) => <span>{record.views == 0 ? 0 : record.views}</span>,
      key: "bannerClicked",
    },
    {
      title: "Orders Of Banner",
      render: (record) => <span>{record.orderNo}</span>,
      key: "orderNo",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => <Action>{renderAction(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];

  return (
    <>
      <Widget className="p-5">
        <TableHeader />
        <Table columns={columns} apiName="getBanner" recordName="bannerList" />
      </Widget>
      <EditBanner />
      <ViewDescription />
    </>
  );
};

export default Index;
